import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../theme/ThemeContext'
import { Helmet } from 'react-helmet-async'
// import useCurrentPath from '../hooks/useCurrentPath'

export const Head = () => {
  const { systemDarkMode } = useContext(ThemeContext)
  const [icon, setIcon] = useState(systemDarkMode ? './img/logo-text-light.png' : './img/logo-text-dark.png')
  // const location = useCurrentPath()

  useEffect(() => {
    setIcon(systemDarkMode ? './img/logo-text-light.png' : './img/logo-text-dark.png')
  }, [systemDarkMode])

  return (
    <Helmet>
      <link id='favicon' rel='icon' type='image/x-icon' href={icon} />
      {/* <title id='title'>Joel E. Mason{location ? ` - ${location.name}` : ''}</title> */}
    </Helmet>
  )
}
