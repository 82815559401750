import { HelmetProvider } from 'react-helmet-async'
import { ThemeContextProvider } from '../theme/ThemeContext'

const AppStateProvider = ({ children }) => {
  return (
    <ThemeContextProvider>
      <HelmetProvider>{children}</HelmetProvider>
    </ThemeContextProvider>
  )
}

export default AppStateProvider
