const GrayTheme = {
  '--main-bg': 'grey',
  '--main-text': 'black',
  '--topbar-hover': '#585858',
  '--tooltip-bg': 'whitesmoke',
  '--tooltip-text': 'black',
  '--tooltip-border': 'black',
  '--tooltip-shadow': '0 0 10px 0px black',
  '--scrollbar-thumb': 'rgba(0, 0, 0, 0.7)',
  '--scrollbar-track': 'rgba(0, 0, 0, 0.4)',
}

export default GrayTheme
