const LightTheme = {
  '--main-bg': 'white',
  '--main-text': 'black',
  '--topbar-hover': '#ff6700',
  '--tooltip-bg': 'black',
  '--tooltip-text': 'white',
  '--tooltip-border': 'white',
  '--tooltip-shadow': '0 0 10px 0px white',
  '--scrollbar-thumb': 'rgba(0, 0, 0, 0.5)',
  '--scrollbar-track': 'rgba(0, 0, 0, 0.2)',
}

export default LightTheme
