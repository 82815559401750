import { Outlet } from 'react-router-dom'
import ProjectsSideBar from '../components/ProjectsSideBar'
import useCurrentPath from '../hooks/useCurrentPath'
import Markdown from '../components/Markdown'

const Projects = () => {
  const location = useCurrentPath()

  return (
    <div className='projects-container'>
      <ProjectsSideBar />
      <div className='projects-content'>{location.path === 'projects' ? <Markdown file='ProjectPageContent' /> : <Outlet />}</div>
      {location.path === 'projects' && (
        <div className='projects-footer'>
          <hr />
          <div className='text'>Markdown editing provided by:</div>
          <a href='https://github.com/uiwjs/react-md-editor' className='link' target='_blank' rel='noreferrer'>
            <img src='./img/markdownProvider.png' alt='Markdown Provider' />
          </a>
        </div>
      )}
    </div>
  )
}

export default Projects
