import DarkTheme from './Dark'
import LightTheme from './Light'
import GrayTheme from './Gray'

class Themes {
  static themes = { dark: DarkTheme, light: LightTheme, gray: GrayTheme }
  static setRootVariables = function (which) {
    Object.keys(this.themes[which]).forEach(key => {
      document.documentElement.style.setProperty(key, this.themes[which][key])
    })
  }
}

export default Themes
