import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon, faCloud } from '@fortawesome/free-solid-svg-icons'
import ToolTip from './ToolTip'
import { useContext } from 'react'
import { ThemeContext } from '../theme/ThemeContext'

const ThemeToggler = () => {
  const { theme, cycleThemes } = useContext(ThemeContext)

  const icon = {
    dark: {
      icon: <FontAwesomeIcon icon={faCloud} />,
      text: 'Switch to Gray',
    },
    gray: {
      icon: <FontAwesomeIcon icon={faSun} />,
      text: 'Switch to Light',
    },
    light: {
      icon: <FontAwesomeIcon icon={faMoon} />,
      text: 'Switch to Dark',
    },
  }

  return (
    <div className='themeTogglerButton tooltip' onClick={cycleThemes} title='Switch Theme'>
      {icon[theme].icon}
      <ToolTip text={icon[theme].text} />
    </div>
  )
}

export default ThemeToggler
