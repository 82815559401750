import { createContext, useState, useEffect } from 'react'
import useCurrentPath from '../hooks/useCurrentPath'
import Themes from '.'

export const ThemeContext = createContext({
  theme: 'dark',
})

export const ThemeContextProvider = ({ children }) => {
  const getSetLocalStorage = theme => {
    if (theme) {
      window.localStorage.setItem('theme', theme)
      return
    }

    let storedTheme = window.localStorage.getItem('theme')
    if (!storedTheme || !['dark', 'light', 'gray'].includes(window.localStorage.getItem('theme'))) {
      storedTheme = 'dark'
      window.localStorage.setItem('theme', storedTheme)
    }
    return storedTheme
  }

  const possibleThemes = ['dark', 'gray', 'light']
  const [theme, setTheme] = useState(getSetLocalStorage())
  const [systemDarkMode, setSystemDarkMode] = useState(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  const location = useCurrentPath()

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    setSystemDarkMode(event.matches)
  })

  useEffect(() => {
    if (!location) {
      setTheme('dark')
      Themes.setRootVariables('dark')
    } else {
      let tTheme = systemDarkMode ? 'dark' : 'light'
      if (getSetLocalStorage() !== tTheme) {
        setTheme(getSetLocalStorage())
        Themes.setRootVariables(getSetLocalStorage())
      } else {
        setTheme(tTheme)
        Themes.setRootVariables(tTheme)
      }
    }
  }, [systemDarkMode, location])

  const cycleThemes = () => {
    let tTheme = ''

    if (possibleThemes.indexOf(theme) === possibleThemes.length - 1) {
      tTheme = possibleThemes[0]
    } else {
      tTheme = possibleThemes[possibleThemes.indexOf(theme) + 1]
    }

    setTheme(tTheme)
    Themes.setRootVariables(tTheme)
    getSetLocalStorage(tTheme)
  }

  return <ThemeContext.Provider value={{ systemDarkMode, theme, cycleThemes }}>{children}</ThemeContext.Provider>
}
