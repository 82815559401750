import { useRouteError } from 'react-router-dom'
import { Head } from '../components/Head'
import TopBar from '../components/TopBar'
import AppStateProvider from '../state'

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)

  return (
    <AppStateProvider>
      <Head />
      <TopBar />
      <div className='container'>
        <div className='errorPage'>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
          {error.status === 404 && (
            <>
              <p>
                <a href='/'>Go back to the home page</a>
              </p>
              <p>
                <img src='./img/404.png' alt='404 Not Found' />
              </p>
            </>
          )}
        </div>
      </div>
    </AppStateProvider>
  )
}
