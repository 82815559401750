import { useLocation } from 'react-router-dom'
import { Routes } from './useRoutes'

const useCurrentPath = () => {
  const { pathname } = useLocation()
  const sPath = pathname.split('/')
  const location = sPath[sPath.length - 1]
  let locationObject = null

  if (!location) {
    return false
  }

  ;[...Routes[0].children, ...Routes[0].children[1].children].forEach(route => {
    if (route.path === location) {
      locationObject = route
    }
  })

  return locationObject || false
}

export default useCurrentPath
