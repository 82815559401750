import ThemeToggler from './ThemeToggler'
import { getTopbarRoutes } from '../hooks/useRoutes'
import useCurrentPath from '../hooks/useCurrentPath'

const TopBar = () => {
  const location = useCurrentPath()
  const tbRoutes = getTopbarRoutes()

  return (
    <div className='topbarContainer'>
      <div className='topbar'>
        <div className='topbarLeft'>
          <a href='/' className='logo'>
            JEM
          </a>
          <div className='topbarLinks'>
            {tbRoutes.map((path, index) => (
              <a key={`tbp-${path.text}-${index}`} href={`/${path.path}`} className={`topbarLink${(location?.sideBar && path.text === 'Projects') || location.path === path.path ? ' active' : ''}`}>
                {path.text}
              </a>
            ))}
          </div>
        </div>

        <div className='topbarRight'>{location && location.path !== '/' && <ThemeToggler />}</div>
      </div>
    </div>
  )
}

export default TopBar
