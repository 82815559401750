import { useEffect, useState } from 'react'
import MDEditor from '@uiw/react-md-editor'
import { RingLoader } from 'react-spinners'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon, faPenToSquare, faFloppyDisk } from '@fortawesome/free-solid-svg-icons'
import ToolTip from '../components/ToolTip'

const icon = {
  dark: {
    icon: <FontAwesomeIcon icon={faSun} />,
    text: 'Switch to Light',
  },
  light: {
    icon: <FontAwesomeIcon icon={faMoon} />,
    text: 'Switch to Dark',
  },
  edit: {
    icon: <FontAwesomeIcon icon={faPenToSquare} />,
    text: 'Edit Markdown',
  },
  save: {
    icon: <FontAwesomeIcon icon={faFloppyDisk} />,
    text: 'Save Markdown',
  },
}

const Markdown = ({ file }) => {
  const [postContent, setPostContent] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [darkMode, setDarkMode] = useState(true)
  const [editMode, setEditMode] = useState(false)

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
  }

  const toggleEdit = () => {
    setEditMode(!editMode)
  }

  useEffect(() => {
    setIsLoading(true)
    import(`../markdown/${file}.md`).then(res => {
      fetch(res.default)
        .then(response => response.text())
        .then(response => {
          setPostContent(response)
          setIsLoading(false)
        })
        .catch(err => setError(err))
    })
  }, [file])

  return (
    <div className='MDEditor-container' data-color-mode={darkMode ? 'dark' : 'light'}>
      {error && (
        <div className='error'>
          <h1>Something went wrong</h1>
          <p>{error}</p>
          <p>Try refreshing the page</p>
        </div>
      )}
      {isLoading ? (
        <RingLoader color='var(--topbar-hover)' />
      ) : (
        <>
          <div className={`togglers${darkMode ? '' : ' dark-text'}`}>
            <div className='toggler toggle-markdown-darkMode tooltip' onClick={toggleDarkMode} title='Switch Theme'>
              {icon[darkMode ? 'dark' : 'light'].icon}
              <ToolTip text={icon[darkMode ? 'dark' : 'light'].text} />
            </div>
            <div className='toggler toggle-markdown-edit tooltip' onClick={toggleEdit} title='Switch Edit Mode'>
              {icon[editMode ? 'save' : 'edit'].icon}
              <ToolTip text={icon[editMode ? 'save' : 'edit'].text} />
            </div>
          </div>
          {editMode ? <MDEditor className='MDEditor' height='calc(100vh-1px)' value={postContent} onChange={setPostContent} /> : <MDEditor.Markdown className='MDEditor' source={postContent} />}
        </>
      )}
    </div>
  )
}

export default Markdown
