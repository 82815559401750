const Home = () => {
  return (
    <section id='home' className='home-section home home-bg'>
      <div className='home-card-container'>
        <div className='home-content'>
          <p className='title'>Joel E Mason</p>
          <p className='subtitle'>
            Software Engineer <span className='little'>and more</span>
          </p>
          <div className='home-card'>
            <p className='list-heading'>Many Hats of Experience</p>
            <hr />
            <ul className='list'>
              <li className='list-item'>
                <p className='item'>Software Engineer</p>
              </li>
              <li className='list-item'>
                <p className='item'>
                  Creator:{' '}
                  <a href='https://nodeidentity.dev/' target='_blank' rel='noreferrer'>
                    Node Identity Libraries
                  </a>
                </p>
              </li>
              <li className='list-item gap'>
                <p>(under development)</p>
              </li>
              <li className='list-item'>
                <p className='item'>OS CMS - alternative to cPanel</p>
              </li>
              <li className='list-item gap'>
                <p>(on hold for previous)</p>
              </li>
              <li className='list-item'>
                <p className='item'>Writer of Fiction, Non-Fiction, Poetry</p>
              </li>
              <li className='list-item gap'>
                <p className='item'>Wood, Metal Fabricator</p>
              </li>
              <li className='list-item'>
                <p className='item'>Former Truck Driver</p>
              </li>
              <li className='list-item'>
                <p className='item'>Former Equipment Operator</p>
              </li>
              <li className='list-item'>
                <p className='item'>Warehousing and Manufacturing</p>
              </li>
            </ul>
          </div>
          <div className='master'>
            <p>A jack of all trades is a master of none,</p>
            <p>but oftentimes better than a master of one.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home
