import { createBrowserRouter } from 'react-router-dom'
import Root from '../containers/Root'

// pages
import Home from '../pages/Home'
import Projects from '../pages/Projects'
import Resume from '../pages/Resume'
import Contact from '../pages/Contact'

// project pageBreak
import NodeIdentity from '../pages/projectPages/NodeIdentity'
import OSCMS from '../pages/projectPages/OSCMS'

// features
import Bst from '../features/Bst'
import KnightsTour from '../features/KnightsTour'
import Blog from '../features/Blog'

import ErrorPage from '../pages/ErrorPage'

export const Routes = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
        text: 'Home',
        topBar: false,
      },
      {
        path: 'projects',
        element: <Projects />,
        errorElement: <ErrorPage />,
        text: 'Projects',
        topBar: true,
        children: [
          {
            path: 'nodeidentity',
            element: <NodeIdentity />,
            text: 'Node Identity',
            sideBar: true,
          },
          {
            path: 'oscms',
            element: <OSCMS />,
            text: 'OS CMS',
            sideBar: true,
          },
          {
            path: 'bst',
            element: <Bst />,
            text: 'BST Visualizer',
            sideBar: true,
          },
          {
            path: 'knightstour',
            element: <KnightsTour />,
            text: "Knight's Tour",
            sideBar: true,
          },
        ],
      },
      {
        path: 'resume',
        element: <Resume />,
        text: 'Resumé',
        topBar: true,
      },
      {
        path: 'write',
        element: <Blog />,
        text: 'Blog',
        topBar: true,
      },
      {
        path: 'contact',
        element: <Contact />,
        text: 'Contact',
        topBar: true,
      },
    ],
  },
]

export function getTopbarRoutes() {
  return Routes[0].children.filter(route => route.topBar)
}

export function getProjectRoutes() {
  return Routes[0].children.filter(route => route.path === 'projects')[0].children.filter(route => route.sideBar)
}

const useRoutes = () => {
  return createBrowserRouter(Routes)
}

export default useRoutes
