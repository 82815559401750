import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import useCurrentPath from '../hooks/useCurrentPath'
import { getProjectRoutes } from '../hooks/useRoutes'

const ProjectsSideBar = () => {
  const windowWidth = window.innerWidth
  const [show, setShow] = useState(windowWidth < 1420 ? false : true)
  const location = useCurrentPath()
  const sbRoutes = getProjectRoutes()

  return (
    <div className={'sidebar' + (show ? '' : ' sidebar-shrink') + (windowWidth < 400 && show ? ' sidebar-maxWidth' : '')}>
      <div className='sidebar-container'>
        <div className='sidebar-caret' onClick={() => setShow(prev => !prev)}>
          <FontAwesomeIcon icon={show ? faCaretLeft : faCaretRight} />
        </div>
        <div className='sidebar-header noSelect'>Projects</div>
        <div className='sidebarLinks'>
          {sbRoutes.map((path, index) => (
            <a key={`sbp-${path.text}-${index}`} href={`/projects/${path.path}`} className={`sidebarLink${location.path === path.path ? ' active' : ''}`}>
              {path.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProjectsSideBar
