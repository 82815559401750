import { Outlet } from 'react-router-dom'
import { Head } from '../components/Head'
import TopBar from '../components/TopBar'
import AppStateProvider from '../state'

const Root = () => {
  return (
    <AppStateProvider>
      <Head />
      <TopBar />
      <div className='container'>
        <Outlet />
      </div>
    </AppStateProvider>
  )
}

export default Root
